import * as React from "react";
import Layout from "../components/layout/Layout";

const NotFoundPage = () => (
  <Layout>
    <h1>Not Found</h1>
  </Layout>
);

export default NotFoundPage;
